import { createAsyncThunk } from '@reduxjs/toolkit';
import apiKey from '../../../utils/axiosInstance';
export const getAllActions = createAsyncThunk('role/getAllActions', async (body, thunkApi) => {
    let data;
    try {
        const response = await apiKey.get(`/permissions/actions`);
        data = response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(data?.message);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : 'An error occured');
    }
});
