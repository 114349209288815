import { createSlice } from '@reduxjs/toolkit';
import { getAllActions } from './actionThunk';
// ----------------------------------------------------------------------
const initialState = {
    actions: [],
    getActionsStatus: 'idle',
    getActionsError: '',
};
const slice = createSlice({
    name: 'action',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllActions.pending, (state) => {
            state.getActionsStatus = 'loading';
        })
            .addCase(getAllActions.fulfilled, (state, { payload }) => {
            state.getActionsStatus = 'succeeded';
            state.actions = payload.data;
        })
            .addCase(getAllActions.rejected, (state, { error }) => {
            state.getActionsStatus = 'failed';
            state.getActionsError = error?.message || 'An error occured';
        });
    },
});
// Reducer
export default slice.reducer;
